import React, { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { _Object, StoreState } from 'utils/common-functions';
import { projectService } from 'services';
import { Message } from 'primereact/message';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useSelector } from 'react-redux';

// Define the props type
interface AddEditProjectProps {
  visible: boolean;
  initialData: _Object;
  resetForm: () => void;
  getProjects: () => void;
}

const AddEditProject = ({ visible, initialData, resetForm, getProjects }: AddEditProjectProps) => {
  const { languages } = useSelector((state: StoreState) => state.session)

  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})
  const [loading, setLoading] = useState(false)

  const formik: _Object = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      title: Yup.string().label('Title').required(),
      base_language: Yup.string().label('Base Language').required(),
      // description: Yup.string().label('Description').required(),
      // target_languages: Yup.array()
      //   .of(Yup.string()) // Ensure each element in the array is a string
      //   .label('Target Languages')
      //   .min(1, 'At least one target language is required') // Ensure at least one language is selected
      //   .required('Target Languages is required'), // General required message for the field
    }),
    onSubmit: async (values: any) => {
      setLoading(true);
      if (initialData.title) {
        projectService.updateProject(initialData.id, {
          "title": values.title,
          "base_language": values.base_language,
          "description": values.description,
          "target_languages": Array.isArray(values.target_languages)
            ? [values.base_language, ...values.target_languages]
            : values.target_languages
        }).then((data: _Object) => {
          if (!data.error) {
            formik.resetForm()
            getProjects()
            resetForm()
          }
          setLoading(false);
        })
      } else {
        projectService.createProject({
          "title": values.title,
          "base_language": values.base_language,
          "description": values.description,
          "target_languages": Array.isArray(values.target_languages)
            ? [values.base_language, ...values.target_languages]
            : values.target_languages
        }).then((data: _Object) => {
          if (!data.error) {
            formik.resetForm()
            getProjects()
            resetForm()
          }
          setLoading(false);
        })
      }
    }
  });

  return (
    <Dialog header={initialData.title ? "Update Project" : "Add New Project"} visible={visible} style={{ width: '397px' }} onHide={resetForm}>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Project Name<span className='required-field'>*</span></label>
          <InputText id="title" className='form-control'
            placeholder='Add new project'
            aria-describedby="title-help"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title} />
          {formik.errors.title && formik.touched.title && (
            <Message severity="error" text={formik.errors.title} />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="base_language">Base Langauge<span className='required-field'>*</span></label>
          <Dropdown
            name="base_language"
            onChange={formik.handleChange}
            filter
            onBlur={formik.handleBlur}
            value={formik.values.base_language}
            options={languages?.map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))}
            optionLabel="label"
            placeholder="Select langauge" className="w-full" checkmark={true} highlightOnSelect={false} />
          {formik.errors.base_language && formik.touched.base_language && (
            <Message severity="error" text={formik.errors.base_language} />
          )}
        </div>

        <div className="form-group">
          <label htmlFor="username">Target Langauge</label>
          <MultiSelect
            name="target_languages"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.target_languages}
            options={[
              ...((languages ?? [])
                .filter((item) => formik.values.target_languages.includes(item.code))
                .map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))),
              ...((languages ?? [])
                .filter((item) => !formik.values.target_languages.includes(item.code))
                .map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))),
            ]}
            placeholder="Select language"
            display="chip"
            filter={true}
            filterBy="label"
            className="w-full"
          />
        </div>

        <div className="form-group">
          <label htmlFor="description">Description</label>
          <InputTextarea
            className="w-full"
            name="description"
            placeholder="Project description" rows={3}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description} />
          {formik.errors.description && formik.touched.description && (
            <Message severity="error" text={formik.errors.description} />
          )}
        </div>

        <Button loading={loading} label={initialData.title ? "Update" : "Add New Project"} className='primary w-full' />
      </form>
    </Dialog>
  );
};

export default AddEditProject;
