import React from 'react';
import { useLocation } from 'react-router-dom';

import './index.scss'
import { Menu } from 'primereact/menu';
import { DashboardContent } from '../../components';

const Dashboard = () => {
  const location = useLocation();

  const items = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-home', command: () => { console.log('Dashboard clicked') } },
    { label: 'Reports', icon: 'pi pi-fw pi-chart-line', command: () => { console.log('Reports clicked') } },
    { label: 'Settings', icon: 'pi pi-fw pi-cog', command: () => { console.log('Settings clicked') } },
  ];

  return (
    <div className="app-container">
      <div className="sidebar">
        <h2>Dashboard</h2>
        <Menu model={items} className="sidebar-menus" />
      </div>
      
      {location?.pathname === "/dashboard" &&
        <DashboardContent />
      }
    </div>
  );
};

export default Dashboard;
