import React from 'react'

import { Helmet } from 'react-helmet'
import { _Object } from 'utils/common-functions'

// import { _Object } from 'utils/types'

const SEOHeader = (props: _Object) => {
	const { title } = props

	return (
		<Helmet>
			<title>{title} {'| Language Hub'}</title>
		</Helmet>
	)
}
export default SEOHeader