import React, { useEffect, useRef, useState } from 'react';

import { FixedSizeList as List } from 'react-window';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { _Object, doDateFormat, StoreState } from 'utils/common-functions';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { DataTable } from 'primereact/datatable';
import { useSelector } from 'react-redux';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Column } from 'primereact/column';
import { useParams } from 'react-router-dom';
import { projectService } from 'services';
import { ProjectSingle } from 'utils/types';
import UpdateProjectLanguage from 'components/modal/update-project-language';
import { Skeleton } from 'primereact/skeleton';
import AddNewKey from 'components/modal/add-new-key';
import { Dialog } from 'primereact/dialog';
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { Toast } from 'primereact/toast';

// Define the types for translation and the form's values
interface Translation {
  language_code: string;
  value: string;
}

interface FormValues {
  id?: string;
  key: string;
  created_at?: string;
  translations: Translation[];
}

interface RowData {
  id: string;
  language_code: string;
  value: string;
  // Add any other properties that your translations might have
}

interface TextTemplateProps {
  rowData: RowData;
  options: {
    rowIndex: number;
  };
  itemIndex: number;
  editingRow: number | null; // This indicates which row is currently being edited
  setEditingRow: React.Dispatch<React.SetStateAction<number | null>>; // Function to update the editing row
  formik: {
    setFieldValue: (field: string, value: any) => void;
  };
}

const processTranslations = (project: ProjectSingle) => {
  // Destructure target_languages and keys from the project data
  const { target_languages, keys } = project;

  if (keys.length > 0) {
    // Iterate over keys to process translations
    const updatedKeys = keys.map(key => {
      const { translations } = key;

      // Create a map of translations by language_code for quick lookup
      const translationMap = new Map();
      translations?.forEach(translation => {
        translationMap.set(translation.language_code, translation);
      });

      // Filter out translations that are not in target_languages
      const filteredTranslations = translations?.filter(translation =>
        target_languages.includes(translation.language_code)
      ) || [];

      if (translations === null) {
        // Add missing translations for languages in target_languages
        target_languages.forEach(langCode => {
          // Add a new translation with an empty value for missing languages
          filteredTranslations?.push({
            id: "", // Assuming you have a function to generate a unique ID
            language_code: langCode,
            value: ""
          });

        })
      } else {
        // Add missing translations for languages in target_languages
        target_languages.forEach(langCode => {
          if (!translationMap.has(langCode)) {
            // Add a new translation with an empty value for missing languages
            filteredTranslations?.push({
              id: "", // Assuming you have a function to generate a unique ID
              language_code: langCode,
              value: ""
            });
          }
        })
      }

      // Sort the filteredTranslations by language_code
      filteredTranslations?.sort((a, b) => a.language_code.localeCompare(b.language_code));

      // Return the updated key with the filtered and completed translations
      return {
        ...key,
        translations: filteredTranslations
      };
    });
    return {
      ...project,
      keys: updatedKeys
    };
  } else {
    // Create a new object with empty values for each language code dynamically
    const newObject = {
      key: "Default Key",
      translations: target_languages?.map((code) => ({
        id: "", // Assuming you have a function to generate a unique ID
        language_code: code,
        value: "" // Empty value for the new object
      }))
    };
    return {
      ...project,
      keys: [newObject]
    };
  }
};

const Editor = () => {
  const toast = useRef<Toast>(null);

  const { languages } = useSelector((state: StoreState) => state.session)
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [keyVisible, setKeyVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState<string | null>(null);

  const [initialData, setInitialData] = useState<FormValues[]>([]);
  const [projectDetails, setProjectDetails] = useState<ProjectSingle>({} as ProjectSingle);
  // State to track the row being edited
  const [editing, setEditing] = useState<string>("");
  const [editingRow, setEditingRow] = useState<number | null>(null);
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    per_page: 12,
    sort: '-created_at',
    q: ''
  });
  const getProject = () => {
    setLoading(true);
    projectService.getProjectDetails(id, filterData).then((data: ProjectSingle) => {
      if (data.id) {
        setEditingRow(null)

        if (data.keys.length > 0) {
          const updatedProject = processTranslations(data);

          setProjectDetails(data)

          setInitialData(updatedProject.keys)
          setLoading(false);

        } else {
          const newObject: FormValues = {
            key: "Default Key",
            translations: data.target_languages?.map((code) => ({
              language_code: code,
              value: ""
            }))
          };
          if (filterData.q === "" && id) {
            setLoading(true);

            projectService.createTranslations(id, [newObject]).then((data) => {
              if (!data.error) {
                addNewKey()
                setKeyVisible(false)
              }
              if (data.error) {
                setLoading(false);
              }
            })
          } else {
            setLoading(false);
            setInitialData([])
          }
        }
      } else {
        setLoading(false);

      }
      // data.items && setProjects(data)
    })
  }
  useEffect(() => { id && getProject() }, [id, filterData])

  const formik = useFormik<FormValues[]>({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object(),
    onSubmit: async (values: any) => {
      setLoading(true);
    }
  });

  const updateKeyName = (item: any) => {
    const params = {
      "id": item.id,
      "key": item.key
    }
    id && projectService.updateTranslations(id, params).then(() => {
      // console.log("data",data);
      // setLoading(false);
    })
    setEditing("")
  }

  const updateLanguageValue = (item: RowData, itemIndex: number) => {
    if (item.id) {
      const params = {
        "id": item.id,
        "language_code": item.language_code,
        "value": item.value
      }
      formik.values[itemIndex]?.id && projectService.updateTranslationsValues(formik.values[itemIndex]?.id, params).then((data) => {
        if (data.error) {
          toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message || 'Upload Failed' });
        } else {
          toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message || 'Uploaded' });
        }
      })
    } else {
      const params = {
        "id": formik.values[itemIndex]?.id || "",
        "language_code": item.language_code,
        "value": item.value
      }
      item.value && projectService.createLanguageTranslation(id, params).then((data) => {
        if (data.error) {
          toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message || 'Upload Failed' });
        } else {
          toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message || 'Uploaded' });
        }
      })
    }
  }

  const textTemplate: React.FC<TextTemplateProps> = ({ rowData, options, itemIndex, editingRow, setEditingRow, formik }) => {

    const handleBlur = () => {
      updateLanguageValue(rowData, itemIndex);
    };
    if (options.rowIndex === editingRow && editing === ("row-" + itemIndex)) {
      return (
        <div className='language-input flex justify-content-between align-items-center'>
          <InputText
            value={rowData.value}
            onChange={(e) => formik.setFieldValue(`[${itemIndex}].translations[${options.rowIndex}].value`, e.target.value)}
            autoFocus
            onBlur={handleBlur} // Trigger save when focus is lost
            className='w-full shadow-none border-noround border-none'
          />
          <div className='language-icons flex'>
            <Button icon="pi pi-check" severity="secondary" rounded onClick={() => updateLanguageValue(rowData, itemIndex)} />
            <Button icon="pi pi-times" rounded outlined severity="secondary" aria-label="Cancel" onClick={() => {
              formik.setFieldValue(`[${itemIndex}].translations[${options.rowIndex}].value`, initialData[itemIndex].translations[options.rowIndex].value)
              setEditingRow(null)
            }} />
          </div>
        </div>
      );
    } else {
      return (
        <span className={rowData.value ? "" : "empty"} onClick={() => {
          setEditing("row-" + itemIndex)
          setEditingRow(options.rowIndex)
        }}>
          {rowData.value || "Empty"}
        </span>
      );
    }
  };

  // Function to handle the "Copy" button click
  const addNewKey = () => {
    getProject()
  };

  // Function to handle the "Copy" button click
  const addExistingKey = (index: number) => {
    const updatedData = initialData.find((_, i) => i === index);
    if (updatedData && updatedData.key) {
      setLoading(true);
      // Create a new object with empty values for each language code dynamically
      const newObject: FormValues = {
        key: updatedData?.key + " Copy" || "",
        translations: updatedData?.translations?.map((item) => ({
          language_code: item.language_code,
          value: item.value
        }))
      };

      id && projectService.createTranslations(id, [newObject]).then((data) => {
        if (!data.error) {
          getProject()
          toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Project key copied successfully' });
        }
        if (data.error) {
          toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message });
          setLoading(false);
        }
      })
    }

  };

  // Function to handle the "Delete" button click
  const handleDelete = () => {
    setLoading(true)
    deleteModal && projectService.deleteTranslation(deleteModal).then((data) => {
      if (!data.error) {
        getProject()
        setDeleteModal(null)
        toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message });
      }
      if (data.error) {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message });
        setLoading(false);
      }
      // data.items && setProjects(data)
    })
  };

  const footerContent = (
    <div className='flex justify-content-center gap-2'>
      <Button label="No" icon="pi pi-times" onClick={() => setDeleteModal(null)} className="p-button-text" severity="danger" outlined disabled={loading} />
      <Button label="Yes" icon="pi pi-check" onClick={handleDelete} autoFocus loading={loading} className='primary' />
    </div>
  );

  const getLanguageName = (code: string) => {
    const language = languages?.find(lang => lang.code === code);
    return language ? language.name : code; // Return the code if not found
  };

  const option = [
    { name: 'First added', value: 'created_at' },
    { name: 'Last added', value: '-created_at' },
    { name: 'Key name A-Z', value: 'key' },
    { name: 'Key name Z-A', value: '-key' },
  ];

  const formikFilter = useFormik({
    initialValues: filterData,
    enableReinitialize: true,
    onSubmit: (values: _Object) => {
      setFilterData((prev) => ({
        ...prev,
        q: values.q.trim()
      }));
      // if (values.q.trim() !== '') {
      //   setFilterData((prev) => ({
      //     ...prev,
      //     q: values.q.trim(),
      //     page: 1
      //   }));
      // }
    }
  });

  const onPageChange = (event: PaginatorPageChangeEvent) => {
    const currentPage = Math.floor(event.first / event.rows) + 1;

    setFilterData((prev) => ({
      ...prev,
      page: currentPage,
      per_page: event.rows,
    }));
  };

  return (
    <>
      <div>
        <Toast ref={toast}></Toast>
        <div className='flex justify-content-between align-items-center'>
          <div className='flex'>
            <form onSubmit={formikFilter.handleSubmit}>
              <div className='form-group mb-0 mr-2'>
                <Button type='submit' className='search-loading bg-transparent p-0 border-none'
                  disabled={loading}
                  loading={loading}>
                  {loading && <i className="pi pi-spin pi-spinner"
                    style={{ fontSize: '1rem' }}
                  >
                  </i>}
                </Button>

                <IconField iconPosition="left">
                  <InputIcon className="pi pi-search"> </InputIcon>
                  <InputText placeholder="Search"
                    name='q'
                    onChange={formikFilter.handleChange}
                    onBlur={formikFilter.handleBlur}
                    value={formikFilter.values.q} />

                </IconField>
                {filterData.q && <Button type='button'
                  onClick={() => {
                    formik.setFieldValue('q', '')
                    setFilterData((prev) => ({
                      ...prev,
                      q: '',
                      page: 1
                    }))
                  }}
                  severity="secondary" text className='bg-transparent p-0 border-none absolute top-0 right-0 mt-2 pt-1 mr-3'>
                  <span className="pi pi-times "> </span>
                </Button>}
              </div>
            </form>
            <div className='form-group mb-0'>
              <Dropdown options={option} value={filterData.sort} onChange={(e) => setFilterData((prev) => ({
                ...prev,
                sort: e.value
              }))} optionLabel="name" placeholder="Sort by" className="md:w-14rem" />
            </div>
          </div>

          <div className='flex gap-4'>
            <Button label="Add New Key" className='primary' onClick={() => setKeyVisible(true)} />
            <Button label="Add Language" severity="danger" outlined onClick={() => setVisible(true)} />
          </div>
        </div>

        <div className='icon-group'>
          <Button label="Key" icon="pi pi-key" className='bg-transparent p-0 text-danger border-none'>&nbsp;{projectDetails.stats?.keys || 0}</Button>
          <Button label="Langauge" icon="pi pi-language" className='bg-transparent p-0 text-danger border-none' >&nbsp;0{projectDetails.stats?.languages || 0}</Button>
          <Button label="Done" icon="pi pi-thumbs-up" className='bg-transparent p-0 text-danger border-none' >&nbsp;{projectDetails.stats?.done || 0}%</Button>
        </div>
        {
          // initialData.length === 0 &&
          loading && <div className='projects-wrap flex bg-white border-1 border-primary-color mt-3'>
            <div className='p-3 max-w-20rem w-20rem border-right-1 border-primary-color'>
              {/* Skeleton for the key */}
              <div className='language-input flex justify-content-between align-items-center'>
                <Skeleton shape="rectangle" width="8rem" className='w-full shadow-none border-noround border-none' />
                <div className='language-icons flex gap-1'>
                  <Skeleton shape="circle" size="2rem" />
                  <Skeleton shape="circle" size="2rem" />
                </div>
              </div>

              {/* Skeleton for Created Date */}
              <Skeleton shape="rectangle" width="10rem" className="mt-2" />

              {/* Skeleton for action buttons */}
              <div className="mt-2 flex gap-2">
                <Skeleton shape="rectangle" width="3rem" height="2rem" />
                <Skeleton shape="rectangle" width="3rem" height="2rem" />
              </div>
            </div>

            <div className='password-strength-table w-full'>
              {/* Skeleton for DataTable */}
              <Skeleton shape="rectangle" width="98%" height="2rem" className="m-2" />
              {[...Array(3)].map((_, idx) => (
                <div key={idx} className="flex justify-between items-center m-2">
                  <Skeleton shape="rectangle" width="8rem" height="3rem" />
                  <Skeleton shape="rectangle" width="100%" height="3rem" className='ml-2' />
                </div>
              ))}
            </div>
          </div>}
        {(formik.values.length > 0) && formik.values?.map((item, i) => (
          <div className='projects-wrap flex bg-white border-1 border-primary-color mt-3 m-0 ' key={i}>
            <div className='p-3 max-w-20rem w-20rem border-right-1 border-primary-color overflow-hidden'>
              {editing === "key-" + i ?
                <div className='language-input flex justify-content-between align-items-center'>
                  <InputText
                    name={`[${i}].key`}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={item.key}
                    autoFocus
                    className='w-full shadow-none border-noround border-none'
                  />
                  <div className='language-icons flex gap-1'>
                    <Button type='button' icon="pi pi-check" severity="secondary" rounded onClick={() => updateKeyName(item)} />
                    <Button type='button' icon="pi pi-times" rounded outlined
                      severity="secondary"
                      aria-label="Bookmark"
                      onClick={() => {
                        formik.setFieldValue(`[${i}].key`, initialData[i].key)
                        setEditing("")
                      }} />
                  </div>
                </div> :
                <h3 className='' onClick={() => setEditing("key-" + i)}>{item.key || ""}</h3>
              }

              <p>Created Date : {doDateFormat(item?.created_at)}</p>

              <Button icon="pi pi-copy" text severity="secondary" className='bg-transparent border-none p-0 w-auto mr-3' onClick={() => addExistingKey(i)} />
              <Button icon="pi pi-trash" text severity="danger" className='bg-transparent border-none p-0 w-auto' onClick={() => setDeleteModal(item.id || null)} />
            </div>

            <div className='password-strength-table w-full'>
              <DataTable
                showGridlines
                value={item.translations}
              // onRowClick={(e) => handleRowClick(e.data, e.index)}  // Handle row click
              >
                <Column
                  field="language_code"
                  header="Language"
                  body={(rowData) => (
                    <div className="flex items-center">
                      <span className="mr-2 p-0" style={{ whiteSpace: "nowrap" }}>{getLanguageName(rowData.language_code)}</span>
                      {/* <span>{languages?.find(lang => lang.code === rowData.language_code)?.flag}</span> */}
                    </div>
                  )}
                />
                <Column
                  body={(rowData, options) =>
                    textTemplate({
                      rowData,
                      options,
                      itemIndex: i, // Pass the current item's index
                      editingRow,
                      setEditingRow,
                      formik,
                    })}
                  header="Text"
                /></DataTable>
            </div>
          </div>
        ))}
        {formik.values?.length === 0 && !loading && <div style={{ textAlign: 'center' }}>
          <span>No record found</span>
        </div>}

        <AddNewKey keyVisible={keyVisible} initialData={projectDetails} setKeyVisible={setKeyVisible} addNewKey={addNewKey} />
        <UpdateProjectLanguage visible={visible} initialData={projectDetails} setVisible={setVisible} getProjects={getProject} />

        <Dialog header="Delete" visible={deleteModal !== null} style={{ width: '25vw' }} onHide={() => { if (!deleteModal) return; setDeleteModal(null); }} footer={footerContent}>
          <p className="m-0">
            Are you sure you want to delete this project.
          </p>
          {/* {response.error && (
          <div className="form-group mt-3">
            <Message severity="error" text={response.message || ""} />
          </div>
        )}
        {response.message && !response.error && (
          <div className="form-group mt-3">
            <Message severity="success" text={response.message || ""} />
          </div>
        )} */}

        </Dialog>
      </div>
      {formik.values.length > 0 && projectDetails.pagination?.total_pages > 1 &&
        <div className='mt-5'>
          <Paginator
            first={(filterData.page - 1) * filterData.per_page}
            rows={filterData.per_page}
            totalRecords={projectDetails.pagination?.total_items || 0}
            rowsPerPageOptions={[12, 25, 50]}
            onPageChange={onPageChange}
          />
        </div>}

    </>
  );
};

export default Editor;
