export const routes = {
	login: '/login',
	resetPassword: '/reset-password',
	forgotPassword: '/forgot-password',
	register: '/register',
	accountVerification: '/account-verification',
	updatePassword: '/update-password',
	dashboard: '/dashboard',
	profile: '/profile',
	projects: '/projects',
	project: '/project',
	
}
