import React, { useState } from 'react';

import { useDispatch } from 'react-redux';
import { setDestroyAuth } from '../redux/slices/session.slice';

import '../pages/dashboard/index.scss';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';

import { Panel } from 'primereact/panel';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Menubar } from 'primereact/menubar';
import { useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';

const DashboardContent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [globalFilter, setGlobalFilter] = useState<string | null>(null);

  const handleLogout = () => {
    dispatch(setDestroyAuth())
  };

  const viewDetails = (product: any) => {
    alert(`Viewing details for ${product.name}`);
  };

  const editProduct = (product: any) => {
    alert(`Editing ${product.name}`);
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <>
        <Button label="View" icon="pi pi-search" className="p-button-sm p-button-info" onClick={() => viewDetails(rowData)} />
        <Button label="Edit" icon="pi pi-pencil" className="p-button-sm p-button-warning p-ml-2" onClick={() => editProduct(rowData)} />
      </>
    );
  };

  const menubarItems = [
    {
      label: 'Home',
      icon: 'pi pi-home',
      command: () => navigate('/')
    },
    {
      label: 'Settings',
      icon: 'pi pi-cog',
      command: () => navigate('/settings')
    },
    {
      label: 'Profile',
      icon: 'pi pi-user',
      command: () => navigate('/profile')
    },
    {
      label: 'Logout',
      icon: 'pi pi-sign-out',
      command: handleLogout
    }
  ];

  const languageTranslations: any = [
    { id: 1, language: 'English', code: 'en', status: 'Completed', completion: '100%', lastUpdated: '2024-09-15' },
    { id: 2, language: 'Spanish', code: 'es', status: 'In Progress', completion: '85%', lastUpdated: '2024-09-10' },
    { id: 3, language: 'French', code: 'fr', status: 'Completed', completion: '100%', lastUpdated: '2024-09-12' },
    { id: 4, language: 'German', code: 'de', status: 'Not Started', completion: '0%', lastUpdated: '2024-09-01' },
    { id: 5, language: 'Chinese', code: 'zh', status: 'In Progress', completion: '65%', lastUpdated: '2024-09-08' },
    { id: 6, language: 'Japanese', code: 'ja', status: 'Completed', completion: '100%', lastUpdated: '2024-09-14' },
    { id: 7, language: 'Italian', code: 'it', status: 'In Progress', completion: '75%', lastUpdated: '2024-09-07' },
    { id: 8, language: 'Portuguese', code: 'pt', status: 'Completed', completion: '100%', lastUpdated: '2024-09-15' },
    { id: 9, language: 'Russian', code: 'ru', status: 'Not Started', completion: '0%', lastUpdated: '2024-09-03' },
    { id: 10, language: 'Korean', code: 'ko', status: 'In Progress', completion: '55%', lastUpdated: '2024-09-05' },
    { id: 11, language: 'Arabic', code: 'ar', status: 'Completed', completion: '100%', lastUpdated: '2024-09-13' },
  ];

  return (
    <div className="content">
      <Menubar model={menubarItems} start={<h3>Dashboard</h3>} />
      <Panel>
        <h2>Language translations list</h2>
        <div className="dashboard-table-header">
          <Button label="Add New Language" icon="pi pi-plus" className="p-mb-3 p-button-success" onClick={() => alert('Add New Product')} />
          <span className="p-input-icon-left p-mb-3">
            <i className="pi pi-search" />
            <InputText value={globalFilter || ''} onChange={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
        </div>
        <DataTable value={languageTranslations} paginator rows={5} globalFilter={globalFilter} className="p-datatable-sm" sortField="name" sortOrder={1}>
          <Column field="id" header="ID" sortable filter></Column>
          <Column field="language" header="language" sortable filter></Column>
          <Column field="code" header="code" sortable filter></Column>
          <Column field="status" header="status" sortable filter></Column>
          <Column field="completion" header="completion" sortable filter></Column>
          <Column field="lastUpdated" header="lastUpdated" sortable filter></Column>
          <Column body={actionBodyTemplate} header="Actions"></Column>
        </DataTable>
      </Panel>
    </div>
  );
};

export default DashboardContent;
