import { MouseEvent, useEffect, useState } from 'react';

import './index.scss'

import { useFormik } from 'formik';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Card } from 'primereact/card';
import { Chip } from 'primereact/chip';
import { Dialog } from 'primereact/dialog';

import { AddEditProject, Header, Sidebar } from 'components';
import { Link } from 'react-router-dom';
import { _Object, doDateFormat } from 'utils/common-functions';
import { projectService } from 'services';
import { Message } from 'primereact/message';
import { PaginationType, ProjectsType } from 'utils/types';
import SEOHeader from 'components/seo-header';
import { Skeleton } from 'primereact/skeleton';
import { Divider } from 'primereact/divider';


export interface DataResponse {
  items: ProjectsType[];
  pagination: PaginationType;
}

const Projects = () => {
  const [visible, setVisible] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [projects, setProjects] = useState<DataResponse | null>(null);
  const [filterData, setFilterData] = useState<_Object>({
    page: 1,
    size: 10,
    sort: '-created_at',
    q: ''
  });
  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})

  const getProjects = () => {
    setLoading(true);
    projectService.getProjects(filterData).then((data: DataResponse) => {
      data.items && setProjects(data)
      setLoading(false);
    })
  }
  useEffect(() => { getProjects() }, [filterData])

  const [initialData, setInitialData] = useState<_Object>({
    title: "",
    base_language: "",
    description: "",
    target_languages: [],
  });

  const resetForm = () => {
    setInitialData({
      title: "",
      base_language: "",
      description: "",
      target_languages: [],
    })
    if (!visible) return
    setVisible(false)
  }
  const handleEditorClick = (event: MouseEvent<HTMLButtonElement>, item: ProjectsType, type: string) => {
    event.preventDefault();
    if (type == "edit") {
      setInitialData(item)
      // Opens the modal
      setVisible(true)
    } else {
      setDeleteModal(true)
      setInitialData(item)
    }
  };

  const deleteItem = () => {
    setLoading(true);
    projectService.deleteProject(initialData.id).then((data: _Object) => {
      setResponse({})

      if (!data.error) {
        resetForm()
        getProjects()
        setDeleteModal(false)
      } else {
        setResponse(data)
      }
      setLoading(false);
    })
  }

  const footerContent = (
    <div className='flex justify-content-center gap-2'>
      <Button label="No" icon="pi pi-times" onClick={() => setDeleteModal(false)} className="p-button-text" severity="danger" outlined disabled={loading} />
      <Button label="Yes" icon="pi pi-check" onClick={deleteItem} autoFocus loading={loading} className='primary' />
    </div>
  );

  const formik = useFormik({
    initialValues: filterData,
    enableReinitialize: true,
    onSubmit: (values: _Object) => {
      setFilterData((prev) => ({
        ...prev,
        q: values.q.trim(),
        page: 1
      }));
      // if (values.q.trim() !== '') {
      //   setFilterData((prev) => ({
      //     ...prev,
      //     q: values.q.trim(),
      //     page: 1
      //   }));
      // }
    }
  });

  return (
    <>
      <SEOHeader title="Projects" />
      <div className="flex h-screen">
        <Sidebar />

        <div className='dashboar-content w-full'>
          <Header />

          <div className="dashboar-wrap">
            <div className="col">
              <div className='flex justify-content-between align-items-center'>
                <form onSubmit={formik.handleSubmit}>
                  <div className='form-group mb-0'>
                    <Button type='submit' className='search-loading bg-transparent p-0 border-none'
                      disabled={loading}
                      loading={loading}>
                      {loading && <i className="pi pi-spin pi-spinner"
                        style={{ fontSize: '1rem' }}
                      >
                      </i>}
                    </Button>

                    <IconField iconPosition="left">
                      <InputIcon className="pi pi-search"> </InputIcon>
                      <InputText placeholder="Search project"
                        name='q'
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.q} />

                    </IconField>
                    {filterData.q && <Button type='button'
                      onClick={() => {
                        formik.setFieldValue('q', '')
                        setFilterData((prev) => ({
                          ...prev,
                          q: '',
                          page: 1
                        }))
                      }}
                      severity="secondary" text className='bg-transparent p-0 border-none absolute top-0 right-0 mt-2 pt-1 mr-3'>
                      <span className="pi pi-times "> </span>
                    </Button>}
                  </div>
                </form>
                <Button label="Add New Project" className='primary' onClick={() => setVisible(true)} />
              </div>
            </div>

            <AddEditProject visible={visible} initialData={initialData} resetForm={resetForm} getProjects={getProjects} />

            <div className="grid card-items mt-3 mx-0">
              {loading ?
                [...Array(3)].map((_, idx) => (
                  <div className="col-4">
                    <div className="p-card border-round surface-card p-4">
                      <div className="flex mb-3">
                        <div>
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                          <Skeleton height=".5rem" width="6rem"></Skeleton>
                        </div>
                      </div>
                      <div className="flex gap-4 mt-4">
                        <Skeleton width="4rem" height="1.5rem"></Skeleton>
                        <Skeleton width="4rem" height="1.5rem"></Skeleton>
                        <Skeleton width="4rem" height="1.5rem"></Skeleton>
                      </div>
                      <Divider></Divider>
                      <div className="flex justify-content-between mt-3">
                        <div className='flex gap-4'>
                          <Skeleton width="4rem" height="1.5rem"></Skeleton>
                          <Skeleton width="4rem" height="1.5rem"></Skeleton>
                          <Skeleton width="4rem" height="1.5rem"></Skeleton>
                        </div>
                        <Skeleton width="1.5rem" height="1.5rem"></Skeleton>
                      </div>
                    </div>
                  </div>
                ))
                :
                <>
                  {projects?.items?.map((item, i: number) => (
                    <div className="col-4">
                      <Link to={`/project/${item.id}`} key={i}>
                        <Card title={
                          <div className="flex justify-content-between align-items-center">
                            <span>{item.title}</span>
                            <Button onClick={(e) => handleEditorClick(e, item, "edit")} icon="pi pi-pencil" className="bg-transparent border-none p-0 ml-2" style={{ color: 'black' }} />
                          </div>
                        }
                          subTitle={`Created Date ${doDateFormat(item.created_at)}`} >
                          <Chip label={`${item.stats?.keys || 0}`} icon="pi pi-key" className='bg-transparent p-0 mr-5' />
                          <Chip label={`${item.stats?.languages || 0}`} icon="pi pi-language" className='bg-transparent p-0 mr-5' />
                          <Chip label={`${item.stats?.done || 0}%`} icon="pi pi-thumbs-up" className='bg-transparent p-0 mr-5' />

                          <footer className='border-top-1 border-primary-color flex justify-content-between align-items-center'>
                            <div className='icon-group mt-0'>
                              <Button label="Editor" icon="pi pi-file-edit" className='bg-transparent p-0 text-danger border-none'

                              />
                              <Button label="Upload" icon="pi pi-upload" className='bg-transparent p-0 text-danger border-none' />
                              <Button label="Download" icon="pi pi-download" className='bg-transparent p-0 text-danger border-none' />
                            </div>

                            <Button icon="pi pi-trash" rounded text severity="danger" aria-label="Delete" className='h-auto w-auto p-0'
                              onClick={(e) => handleEditorClick(e, item, "delete")} />
                          </footer>
                        </Card>
                      </Link>
                    </div>
                  ))}
                </>
              }

            </div>

            {projects?.items?.length === 0 && !loading && <div style={{ textAlign: 'center' }}>
              <span>No record found</span>
            </div>}
          </div>
        </div >
      </div >

      <Dialog header="Delete" visible={deleteModal} style={{ width: '25vw' }} onHide={() => { if (!deleteModal) return; setDeleteModal(false); }} footer={footerContent}>
        <p className="m-0">
          Are you sure you want to delete this project.
        </p>
        {response.error && (
          <div className="form-group mt-3">
            <Message severity="error" text={response.message || ""} />
          </div>
        )}
        {response.message && !response.error && (
          <div className="form-group mt-3">
            <Message severity="success" text={response.message || ""} />
          </div>
        )}

      </Dialog>
    </>
  );
};

export default Projects;
