import store from 'store';

export default class CommonService {
	baseURL: string;

	constructor() {
		this.baseURL = process.env.REACT_APP_API_URL || '';
	}

	async request(endpoint: string, method: string, params?: any) {
		const headers: Record<string, string> = {
			'Authorization': `${store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcKeg42Wb9')}`
		};

		// If params is not FormData, set the Content-Type header to application/json
		if (!(params instanceof FormData)) {
			headers['Content-Type'] = 'application/json';
		}

		const config: RequestInit = { method, headers };

		// If it's not a GET request, add the body
		if (method !== 'GET' && params) {
			config.body = params instanceof FormData ? params : JSON.stringify(params);
		}

		try {
			const response = await fetch(`${this.baseURL}/v1/${endpoint}`, config);
			const data = await response.json();

			if (response.status === 401) {
				store.clearAll()
				window.location.replace('/login');
			} else if (data.error === false) {
				// toast.success(data?.message, {
				// 	autoClose: 3000
				// })
			} else if (data.error === true) {

				if (data.message === 'Unauthorized access!') {
					store.clearAll()
					window.location.replace('/login');
				}
			}

			return data;
		} catch (error: any) {
			if (error.message === 'Unauthorized access!') {
				store.clearAll();
				window.location.replace('/login');
			}
			return error;
		}
	}

	async get(endpoint: string) {
		return await this.request(endpoint, 'GET');
	}

	async post(endpoint: string, params = {}) {
		return await this.request(endpoint, 'POST', params);
	}

	async put(endpoint: string, params = {}) {
		return await this.request(endpoint, 'PUT', params);
	}

	async delete(endpoint: string) {
		return await this.request(endpoint, 'DELETE');
	}
}
