import React, { useEffect, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { InputText } from 'primereact/inputtext';
import { Avatar } from 'primereact/avatar';
import { Button } from 'primereact/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from 'redux/store';

import './index.scss'

import { Header, Sidebar } from '../../components';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { _Object, StoreState } from 'utils/common-functions';
import { setLoggedInUser } from '../../redux/slices/session.slice';
import { authService } from 'services';
import { Message } from 'primereact/message';
import ChangePassword from 'components/modal/change-password';
import SEOHeader from 'components/seo-header';
import PhoneInput from 'react-phone-input-2';
import { AvatarPlaceholder } from 'assets/images';
import { Image } from 'primereact/image';


const Profile = () => {
  const dispatch = useDispatch<AppDispatch>();

  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})
  const [updateLoader, setUpdateLoader] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const { loggedInUser, loading } = useSelector((state: StoreState) => state.session)

  useEffect(() => {
    if (loggedInUser?.id) {
      setInitialData({
        first_name: loggedInUser?.first_name,
        last_name: loggedInUser?.last_name,
        email: loggedInUser?.email,
        phone_number: loggedInUser?.phone_number,
      });
    } else {
      dispatch(setLoggedInUser());
    }
  }, [loggedInUser?.id]);

  const [initialData, setInitialData] = useState<_Object>({
    first_name: loggedInUser?.first_name,
    last_name: loggedInUser?.last_name,
    email: loggedInUser?.email,
    phone_number: loggedInUser?.phone_number,
  });

  const formik: _Object = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      first_name: Yup.string().label('First Name').required('First name is required'),
      last_name: Yup.string().label('Last Name').required('Last name is required'),
      email: Yup.string().label('Email').required('Email is required').email('Invalid email address'),
      phone_number: Yup.string().label('Phone Number').required('Phone number is required'),
    }),
    onSubmit: async (values: any) => {
      setResponse({})
      delete values.email;
      setUpdateLoader(true)
      authService.editProfile(values).then((result: _Object) => {
        setResponse(result)
        setUpdateLoader(false)
        if (!result.error) {
          authService.getMe().then((result: _Object) => {
            setInitialData(result);
            dispatch(setLoggedInUser())
          });
        }
      });
    }
  });

  return (
    <div className="flex h-screen">
      <Sidebar />
      <SEOHeader title="Profile" />

      <div className='dashboar-content w-full'>
        <Header />

        <div className="dashboar-wrap change-password">
          <div className='bg-white p-4' style={{ width: '397px' }}>
            <div className='flex align-items-center justify-content-center mb-4'>
              <Avatar image={AvatarPlaceholder} style={{ width: '100px', height: "100px" }} shape="circle" />
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="first_name">First name<span className='required-field'>*</span></label>
                <InputText
                  name="first_name"
                  className='form-control'
                  placeholder='First name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                />
                {formik.touched.first_name && formik.errors.first_name ? (
                  <Message severity="error" text={formik.errors.first_name} />
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="last_name">Last name<span className='required-field'>*</span></label>
                <InputText
                  name="last_name"
                  className='form-control'
                  placeholder='Last name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                />
                {formik.touched.last_name && formik.errors.last_name ? (
                  <Message severity="error" text={formik.errors.last_name} />
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email<span className='required-field'>*</span></label>
                <InputText
                  id="email"
                  name="email"
                  className='form-control'
                  placeholder='Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <Message severity="error" text={formik.errors.email} />
                ) : null}
              </div>

              <div className="form-group">
                <label htmlFor="username">Phone<span className='required-field'>*</span></label>
                <PhoneInput
                  country={'us'}
                  value={formik.values.phone_number}
                  onChange={(phone) => {
                    formik.setFieldValue('phone_number', phone || '')
                  }}
                  countryCodeEditable={false}
                  onBlur={formik.handleBlur}
                  placeholder={'Enter number'}
                />
                {/* <InputNumber
                  id="phone_number"
                  name="phone_number"
                  placeholder='Phone'
                  onValueChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_number} useGrouping={false}
                /> */}
                {formik.touched.email && formik.errors.phone_number ? (
                  <Message severity="error" text={formik.errors.phone_number} />
                ) : null}
              </div>

              <Button loading={updateLoader} disabled={loading} type="submit" label="Update Profile" className='primary w-full' />

              {response.error && (
                <div className="form-group mt-3">
                  <Message severity="error" text={response.message || ""} />
                </div>
              )}
              {response.message && !response.error && (
                <div className="form-group mt-3">
                  <Message severity="success" text={response.message || ""} />
                </div>
              )}
            </form>

            <div className="footer flex justify-content-center mt-4">
              <p className='m-0'>
                Do yo want to &nbsp;
                <Button
                  link
                  onClick={() => {
                    setResponse({})
                    setVisible(true)
                  }}
                  className='p-0'
                >
                  Change Password?
                </Button>
              </p>
            </div>

            <ChangePassword setVisible={setVisible} visible={visible} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
