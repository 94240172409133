import store from 'store';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authService } from '../../services';



// Define the LoggedInUser type
interface LanguagesInUser {
  code: string,
  name: string,
  flag: string
}

// Define the LoggedInUser type
interface LoggedInUser {
  created_at: string;
  email: string;
  first_name: string;
  full_name: string;
  id: string;
  last_name: string;
  phone_number: string;
  status: string;
  updated_at: string;
}

// Define the SessionState type
export interface SessionState {
  isUserLoggedIn: boolean;
  loading: boolean;
  languages: [LanguagesInUser] | null;
  loggedInUser: LoggedInUser | null;
  globalSettings: Record<string, any>; // Adjust based on your actual structure
}

// Define initial state
const initialState: SessionState = {
  isUserLoggedIn: store.get(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcKeg42Wb9') ? true : false,
  loggedInUser: null,
  languages: null,
	loading: false,
	globalSettings: {},
};

// Async thunk to fetch logged-in user
export const setLoggedInUser = createAsyncThunk<LoggedInUser>(
  'setLoggedInUser',
  async () => {
    return await authService.getMe();
  }
);

// Async thunk to fetch languages
export const setLanguages = createAsyncThunk<[LanguagesInUser]>(
  'setLanguages',
  async () => {
    return await authService.getLanguages();
  }
);

// Create session slice
const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setTokenSession: (state, action: PayloadAction<string>) => {
      store.set(process.env.REACT_APP_ACCESS_TOKEN_KEY || 'bIESIcKeg42Wb9', action.payload);
      state.isUserLoggedIn = true;
    },
    setDestroyAuth: (state) => {
      store.clearAll();
      state.isUserLoggedIn = false;
    }
  },
  extraReducers: (builder) => {
		builder
			.addCase(setLoggedInUser.pending, (state) => {
				state.loggedInUser = null
				state.loading = true
			})
			.addCase(setLoggedInUser.fulfilled.toString(), (state, action: PayloadAction<LoggedInUser>) => {
				state.loggedInUser = action.payload || {}
				state.loading = false
			})
			.addCase(setLoggedInUser.rejected, (state) => {
				state.loggedInUser = null
				state.loading = false
			})
      .addCase(setLanguages.fulfilled.toString(), (state, action: PayloadAction<[LanguagesInUser]>) => {
				state.languages = action.payload || {}
				state.loading = false
			})
  }
});

// Export actions and reducer
export const { setTokenSession, setDestroyAuth } = sessionSlice.actions;
export default sessionSlice.reducer;
