import React, { useEffect } from 'react';

import { BreadCrumb } from 'primereact/breadcrumb';
import { Avatar } from 'primereact/avatar';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch } from 'redux/store';
import { setLanguages, setLoggedInUser } from '../../redux/slices/session.slice';
import { routes, StoreState } from 'utils/common-functions';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import './index.scss'
import { AvatarPlaceholder } from 'assets/images';

// Define the types for translation and the form's values
interface HeaderProps {
  projectName?: string;
}

const Header = ({ projectName = "" }: HeaderProps) => {
  const { pathname } = useLocation()
  const { id } = useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()

  const { loggedInUser } = useSelector((state: StoreState) => state.session)

  useEffect(() => {
    if (!loggedInUser?.id) {
      dispatch(setLanguages())
      dispatch(setLoggedInUser())
    }
  }, [])

  const items = [
    { label: 'Projects', icon: 'pi pi-fw pi-home', command: () => navigate('/projects') },
    { label: projectName, icon: 'pi pi-fw pi-qrcode', command: () => { console.log('Reports clicked') } }
  ];
  return (
    <header className='main-header bg-white border-bottom-1 border-primary-color'>
      <div>
        <h3 className='m-0'>{pathname !== "/profile" ? "Projects" : "Profile"}</h3>
        {id && <BreadCrumb model={items} className='p-0 border-none border-noround m1-2' />}
      </div>

      <Link to={routes.profile} className='flex align-items-center'>
        <p className='mr-2 my-0 avatar-name' >Hello {loggedInUser?.first_name || ""}</p>
        <Avatar image={AvatarPlaceholder} shape="circle" />
      </Link>
    </header>
  );
};

export default Header;
