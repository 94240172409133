import React, { useEffect, useState } from 'react';

import { StoreState } from 'utils/common-functions';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { projectService } from 'services';
import { ProjectSingle } from 'utils/types';
import { Skeleton } from 'primereact/skeleton';
import { Button } from 'primereact/button';

const Download = () => {
  const { languages } = useSelector((state: StoreState) => state.session)
  const { id } = useParams()
  const [projectDetails, setProjectDetails] = useState<ProjectSingle>({} as ProjectSingle);
  const [loading, setLoading] = useState(false)
  const getProject = () => {
    setLoading(true);
    projectService.getProjectDetails(id).then((data: ProjectSingle) => {
      if (data.id) {
        setProjectDetails(data)
      }
      // data.items && setProjects(data)
      setLoading(false);
    })
  }
  useEffect(() => { id && getProject() }, [id])

  const getLanguageName = (code: string) => {
    const language = languages?.find(lang => lang.code === code);
    return language ? language.name : code; // Return the code if not found
  };

  const getLanguageJson = (code: string) => {
    const params = {
      "download_all": false,
      "language_code": code
    }
    projectService.downloadTranslation(id, params).then((data) => {
      if (!data.error) {
        window.open(data?.data?.link, "_blank");

      }
      // data.items && setProjects(data)
      setLoading(false);
    })
  };

  const getAllLanguageJson = () => {
    const params = {
      "download_all": true
    }
    projectService.downloadTranslation(id, params).then((data) => {
      if (!data.error) {
        window.open(data?.data?.link, "_blank");

      }
      // data.items && setProjects(data)
      setLoading(false);
    })
  };

  return (
    <>
      <div className='flex justify-content-between align-items-center'>
        <div className="flex align-items-center">
          {/* <Checkbox inputId="SelectAll" name="pizza" value="Cheese" onChange={onIngredientsChange} checked={ingredients.includes('Cheese')} />
                <label htmlFor="SelectAll" className="ml-2">Select All</label> */}
        </div>

        {/* <div className='flex gap-4'>
          <Button label="Download" className='primary' />
        </div> */}

        <div className='flex gap-4'>
          <Button label="Download All" className='primary' onClick={() => getAllLanguageJson()} />
        </div>
      </div >
      {!projectDetails?.target_languages && loading &&
        <div className="select-items flex flex-wrap mt-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="select-item align-items-center bg-white border-round p-4 flex">
              <Skeleton shape="circle" size="1.5rem" />
              <Skeleton shape="rectangle" width="8rem" className="mx-2" />
            </div>
          ))}
        </div>
      }
      <div className="select-items flex flex-wrap mt-4">
        {projectDetails?.target_languages?.map((item, i) => (
          <Button key={i} label={getLanguageName(item)} onClick={() => getLanguageJson(item)} icon="pi pi-download" text severity="secondary" className='bg-white border-round'></Button>
        ))}
      </div></>
  );
};

export default Download;
