import { useEffect, useRef, useState } from 'react';

import './index.scss'

import { Button } from 'primereact/button';
import { TabView, TabPanel } from 'primereact/tabview';
import { FileUpload, ItemTemplateOptions } from 'primereact/fileupload';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Download, Editor, Header, Sidebar } from 'components';
import { useParams } from 'react-router-dom';
import { ProjectSingle } from 'utils/types';
import { projectService } from 'services';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Dropdown } from 'primereact/dropdown';
import { StoreState } from 'utils/common-functions';
import { useSelector } from 'react-redux';

const Project = () => {
  const { id } = useParams()
  const { languages } = useSelector((state: StoreState) => state.session)

  const [projectDetails, setProjectDetails] = useState<ProjectSingle>({} as ProjectSingle);
  const [loading, setLoading] = useState(false)

  useEffect(() => { id && getProject() }, [id])
  const getProject = () => {
    setLoading(true);
    projectService.getProjectDetails(id).then((data: ProjectSingle) => {
      if (data.id) {
        setProjectDetails(data)
      }
      // data.items && setProjects(data)
      setLoading(false);
    })
  }

  const toast = useRef<Toast>(null);
  const fileUploadRef = useRef<FileUpload>(null);
  const [selectedFiles, setSelectedFiles] = useState<{ language_code: string, file: File }[]>([]);

  const onTemplateSelect = (e: { files: File[] }) => {
    const newFiles = e.files;

    if (newFiles.length === 0) {
      console.log("No files selected.");
      return;
    }

    const formattedFiles = newFiles.map((file, index) => ({
      language_code: ``,
      file: file
    }));

    console.log(formattedFiles);

    setSelectedFiles((prevFiles) => [...prevFiles, ...formattedFiles]);
  };
  
  const uploadFiles = () => {
    // Check if all files have a language_code before uploading
    const allFilesHaveLanguageCode = selectedFiles.every(file => file.language_code !== "");

    if (!allFilesHaveLanguageCode) {
      // Show error toast if any file is missing language_code
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Please select a language for all files before uploading.',
      });
      return;
    }

    if (selectedFiles.length === 0) return; // No files selected
    setLoading(true);

    const formData = new FormData();

    // Append each file with its corresponding language_code
    selectedFiles.forEach((file, index) => {
      // Append file with a unique key
      formData.append(`file_${index}`, file.file);  // file_{index} ensures each file gets a unique key
      formData.append(`language_code_${index}`, file.language_code); // Append language_code with a unique key
    });

    // Call API to upload files
    projectService.importTranslation(id, formData).then((data) => {
      if (!data.error) {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: data.message || 'Files Uploaded' });
        fileUploadRef.current?.clear(); // Clear file input
      } else {
        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Upload Failed' });
      }
      setLoading(false);
    });
  };

  const onTemplateClear = () => {
    setSelectedFiles([]);
  };

  const headerTemplate = (options: any) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />

        <div className="flex align-items-center gap-3 ml-auto">
          {loading ? (
            <div className="loader">
              <ProgressSpinner />
            </div>
          ) : (
            <Button
              disabled={selectedFiles.length === 0}
              onClick={uploadFiles}
              icon="pi pi-fw pi-cloud-upload"
              className='custom-upload-btn p-button-success p-button-rounded p-button-outlined custom-width-button m-0'
            />)}

        </div>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-upload mt-8" style={{ fontSize: '24px', borderRadius: '6px', padding: "12px", backgroundColor: 'var(--santas-gray)33', color: 'var(--rhino)' }}></i>
        <h4 className="mt-6 mb-2">Drop files here or click to upload</h4>
        <p className='mt-0 mb-8'>Supported files: zip, json</p>
      </div>
    );
  };
  const onBeforeDrop = (event: DragEvent) => {
    const dataTransfer = event.dataTransfer;

    if (!dataTransfer) return;

    const files = Array.from(dataTransfer.files);
    const validFiles = files.filter((file) => {
      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      return fileExtension === 'zip' || fileExtension === 'json';
    });

    if (validFiles.length !== files.length) {
      toast.current?.show({
        severity: 'error',
        summary: 'Invalid File Type',
        detail: 'Only .zip and .json files are allowed.',
        life: 3000,
      });
      return false;
    }
  };

  const chooseOptions = { icon: 'pi pi-fw pi-file-import', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };

  const onFileTypeChange = (fileName: string, newType: string) => {
    // Update the language_code in the selectedFiles state
    setSelectedFiles((prevFiles) =>
      prevFiles.map((file) =>
        file.file.name === fileName
          ? { ...file, language_code: newType }
          : file
      )
    );
  };

  // Handle file removal from selectedFiles state
  const handleFileRemove = (fileName: string) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.file.name !== fileName)
    );
  };

  // Item template for displaying the files in the upload component
  const itemTemplate = (file: any, options: ItemTemplateOptions) => {
    const fileName = file.name; // File name
    const fileSize = (file.size / 1024).toFixed(2) + ' KB'; // File size in KB
    const selectedFile = selectedFiles.find((f) => f.file.name === fileName);
    const selectedType = selectedFile?.language_code || '';
    return (
      <div className="flex align-items-center gap-3" style={{ justifyContent: 'space-between', padding: '0.5rem 0' }}>
        <div>
          <p>{fileName} <span style={{ fontSize: '0.55rem', margin: 0 }}>{fileSize}</span></p>
        </div>
        <div className='col-6'>
          <div className="flex align-items-center gap-3" style={{ justifyContent: 'space-between', padding: '0.5rem 0' }}>
            <Dropdown
              name="base_language"
              onChange={(e) => onFileTypeChange(fileName, e.value)}
              filter
              value={selectedType}
              options={languages?.map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))}
              optionLabel="label"
              placeholder="Select language"
              className="w-full"
              checkmark={true}
              highlightOnSelect={false}
            />
            <Button
              icon="pi pi-times"
              className="p-button-text p-button-danger"
              onClick={() => {
                options.onRemove(file)
                handleFileRemove(fileName)
              }} // Remove file from selectedFiles
              // onClick={() => options.onRemove(file)} // Use options.onRemove to remove the file

              tooltip="Remove File"
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="flex h-screen">
      <Sidebar />

      <div className='dashboar-content project-content w-full'>
        <Header projectName={projectDetails.title} />

        <TabView className='bg-transparent'>
          <TabPanel header="Editor">
            <Editor />
          </TabPanel>


          <TabPanel header="Upload">
            <div className='flex justify-content-center upload-wrap'>
              <div>
                <h2 className="mb-1 text-center">Upload localization files</h2>
                <p className='mb-5 text-center'>You can upload single zip files.</p>
                <Toast ref={toast}></Toast>

                <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                <FileUpload
                  ref={fileUploadRef}
                  multiple
                  disabled={loading}
                  accept=".zip,.json" // Restrict to zip and json files
                  maxFileSize={100000000}
                  onSelect={onTemplateSelect}
                  onClear={onTemplateClear}
                  itemTemplate={itemTemplate}
                  headerTemplate={headerTemplate}
                  emptyTemplate={emptyTemplate}
                  chooseOptions={chooseOptions}
                  onBeforeDrop={onBeforeDrop}
                  style={{ width: '800px' }}
                />;
              </div>
            </div>
          </TabPanel>

          <TabPanel header="Download">
            <Download />
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
};

export default Project;
