import Dashboard from "../pages/dashboard";
import Project from "../pages/project";
import Projects from "../pages/projects";
import Profile from "../pages/profile";

const PrivateRoutes = [
  { path: `/dashboard`, exact: true, name: 'dashboard', component: Dashboard },
  { path: `/projects`, exact: true, name: 'project', component: Projects },
  { path: `/project/:id`, exact: true, name: 'project', component: Project },
  { path: `/profile`, exact: true, name: 'profile', component: Profile },
]

export default PrivateRoutes;