import { Menu } from 'primereact/menu';
import { LogoImg } from '../../assets/images';
import { setDestroyAuth } from '../../redux/slices/session.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './index.scss'
import { useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // State to control the visibility of the confirmation dialog
  const [isDialogVisible, setIsDialogVisible] = useState(false);

  const handleLogout = () => {
    dispatch(setDestroyAuth());
    setIsDialogVisible(false); // Close dialog after confirming logout
  };

  const confirmLogout = () => {
    setIsDialogVisible(true); // Show the dialog
  };

  const footerItems = [
    { label: 'Sign out', icon: 'pi pi-fw pi-sign-out', command: confirmLogout },
  ];

  const items = [
    // { label: 'Dashboard', icon: 'pi pi-fw pi-home', command: () => { console.log('Dashboard clicked') } },
    { label: 'Projects', icon: 'pi pi-fw pi-qrcode', command: () => navigate('/projects') },
    { label: 'Help', icon: 'pi pi-fw pi-question-circle', command: () => { console.log('Reports clicked') } },
    { label: 'Profile', icon: 'pi pi-fw pi-user', command: () => navigate('/profile') },
  ];
  return (
    <div className="main-sidebar bg-white flex flex-column h-full">
      <div className='sidebar-logo flex justify-content-center align-items-center border-bottom-1 border-primary-color'>
        <a href='/'><img src={LogoImg} alt="Logo" className='flex w-8rem' /></a>
      </div>

      <div className='menu-items-list overflow-y-auto'>
        <Menu model={items} className="w-full border-none " />
      </div>

      <div className='menu-items-list footer-menu mt-auto border-top-1 border-primary-color'>
        <Menu model={footerItems} className='border-none w-full' />
      </div>
      
      {/* Confirmation Dialog */}
      <Dialog
        visible={isDialogVisible}
        style={{ width: '25vw' }}
        onHide={() => setIsDialogVisible(false)}
        header="Confirm Logout"
        footer={
          <div className="flex justify-content-center">
            <Button onClick={() => setIsDialogVisible(false)} className="p-button-text" severity="danger" label="No" outlined/>
            <Button onClick={handleLogout} className="primary" label="Yes"/>
          </div>
        }
      >
        <p className="m-0">Are you sure you want to sign out?</p>
      </Dialog>
    </div>
  );
};

export default Sidebar;
