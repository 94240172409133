import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

import { loginImage, LogoImg } from '../../../assets/images';
import { authService } from 'services';
import { Link } from 'react-router-dom';
import SEOHeader from 'components/seo-header';

const ForgetPassword: React.FC = () => {
  const [loading, setLoading] = useState(false)

  const [error, setError] = useState<{
    message?: string
    error?: boolean,
  }>({})

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      email: Yup.string().label('Email').required().email()
    }),

    onSubmit: async (values) => {
      setError({})
      setLoading(true)
      authService.forgotPassword(values)
        .then((data) => {
          setError(data)
          setLoading(false)
        })
    }
  })
  return (
    <>
      <SEOHeader title="Forget Password" />
      <div className="auth-details flex h-screen">
        <div className="flex align-items-center justify-content-center flex-column w-6">
          <div className="login-width">
            <img src={LogoImg} alt="Clapping Hands" className='w-13rem' />
            <h2 className="flex align-items-center my-2">Forgot Password? 🔒</h2>
            <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <div className="login-input">
                  <label htmlFor="email">Email<span className='required-field'>*</span></label>
                  <InputText
                    id="email"
                    className={`form-control ${formik.errors.email && formik.touched.email ? 'p-invalid' : ''}`}
                    name="email"
                    placeholder='Email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <Message severity="error" text={formik.errors.email} />
                  )}
                </div>
              </div>

              <div className="form-group flex justify-content-center">
                <Button loading={loading} type="submit" label="Send reset link" className='primary w-full' />
                {error.error && (
                  <Message severity="error" text={error.message} />
                )}
              </div>

              {error.message && !error.error && (
                <div className="form-group m-0">
                  <Message severity="success" text={error.message} />
                </div>
              )}

              <div className="account-details flex justify-content-center mt-5">
                <p className='m-0'>
                  <Link to="/login"><i className="pi pi-arrow-left"></i>&nbsp; Back to login</Link>
                </p>
              </div>
            </form>
          </div>

          <div className='copy-right'>
            <p className='m-0'>© 2023 All Rights Reserved</p>
          </div>
        </div>
        <div className="flex justify-content-center align-items-center bg-white w-6">
          <div className="img-container">
            <img src={loginImage} alt="character" className="character-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
