import Login from "../pages/auth/login";
import Register from "../pages/auth/register";
import AccountVerification from "../pages/auth/register/account-verification";
import ForgetPassword from "../pages/auth/forget-password";
import ResetPassword from "../pages/auth/reset-password";

const PublicRoutes = [
  { path: `/login`, exact: true, name: 'Login', component: Login },
  { path: `/register`, exact: true, name: 'Register', component: Register },
  { path: `/account-verification`, exact: true, name: 'Account Verification', component: AccountVerification },
  { path: `/forget-password`, exact: true, name: 'ForgetPassword', component: ForgetPassword },
  { path: `/reset-password`, exact: true, name: 'ResetPassword', component: ResetPassword },
];

export default PublicRoutes;
