import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

import { ClappingHands, loginImage, LogoImg } from '../../../assets/images';
import { Password } from 'primereact/password';
import { authService } from '../../../services';
import { routes } from 'utils/routes';
import { _Object } from 'utils/common-functions';
import SEOHeader from 'components/seo-header';

const ResetPassword: React.FC = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search)
  const id = query.get('id')
  const key = query.get('key')

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)

  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})

  const formik = useFormik({
    initialValues: {
      new_password: '',
      confirm_password: '',
      id: id,
      key: key
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      new_password: Yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character, space not allowed.'
        ),

      confirm_password: Yup
        .string()
        .required('Confirm password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character, space not allowed.'
        ).oneOf([Yup.ref('new_password')], 'Passwords must match')

    }),

    onSubmit: async (values) => {
      setLoading(true)
      setResponse({})
      authService.updatePassword(values)
        .then((data: _Object) => {
          formik.resetForm();
          setResponse(data)
          data.error === false && navigate(routes.login)
          setLoading(false)
        })
    }
  })

  return (
    <>
      <SEOHeader title="Reset Password" />

      <div className="auth-details flex h-screen">
        <div className="flex align-items-center justify-content-center flex-column w-6">
          <div className="login-width">
            <img src={LogoImg} alt="Clapping Hands" className='w-13rem' />
            <h2 className="flex align-items-center my-2">Reset Your Password
              <img src={ClappingHands} alt="Clapping Hands" className='w-2rem' /></h2>
            <p className="mb-4">Enter your new password to regain access to your account.</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group login-password-field">
                <div className="login-input">
                  <label htmlFor="new_password">New password<span className='required-field'>*</span></label>
                  <Password
                    id="new_password"
                    className={`form-control ${formik.errors.new_password && formik.touched.new_password ? 'p-invalid' : ''}`}
                    name="new_password"
                    placeholder="New password"
                    value={formik.values.new_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    toggleMask
                  // invalid={formik.values.password}
                  />
                  {formik.errors.new_password && formik.touched.new_password && (
                    <Message severity="error" text={formik.errors.new_password} />
                  )}
                </div>
              </div>

              <div className="form-group login-password-field">
                <div className="login-input">
                  <label htmlFor="confirm_password">Confirm Password<span className='required-field'>*</span></label>
                  <Password
                    id="confirm_password"
                    className={`form-control ${formik.errors.confirm_password && formik.touched.confirm_password ? 'p-invalid' : ''}`}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    toggleMask
                  // invalid={formik.values.password}
                  />
                  {formik.errors.confirm_password && formik.touched.confirm_password && (
                    <Message severity="error" text={formik.errors.confirm_password} />
                  )}
                </div>
              </div>

              <div className="flex justify-content-center">
                <Button loading={loading} type="submit" label="Reset password" className='primary w-full' />
              </div>

              {response.error && (
                <div className="form-group mt-3">
                  <Message severity="error" text={response.message || ""} />
                </div>
              )}
              {response.message && !response.error && (
                <div className="form-group mt-3">
                  <Message severity="success" text={response.message || ""} />
                </div>
              )}

              <div className="account-details flex justify-content-center mt-3">
                <p className="m-0">Already have an account? &nbsp;<a href="/login">Sign in</a></p>
              </div>
            </form>
          </div>

          <div className='copy-right'>
            <p className='m-0'>© 2023 All Rights Reserved</p>
          </div>
        </div>

        <div className="flex justify-content-center align-items-center bg-white w-6">
          <div className="img-container">
            <img src={loginImage} alt="character" className="character-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
