import React, { useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { _Object } from 'utils/common-functions';
import { authService } from 'services';
import { Message } from 'primereact/message';
import { Password } from 'primereact/password';

// Define the props type
interface ChangePasswordProps {
  visible: boolean; // Adjust type if needed
  setVisible: (visible: boolean) => void; // Define the type for the function
}

const ChangePassword = ({ visible, setVisible }: ChangePasswordProps) => {
  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})
  const [loading, setLoading] = useState(false)

  const formik: _Object = useFormik({
    initialValues: {
      current_passowrd: "",
      new_password: "",
      confirm_password: ""
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      current_passowrd: Yup
        .string()
        .required('Current password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character'
        ),
      new_password: Yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character'
        ),
      confirm_password: Yup
        .string()
        .oneOf([Yup.ref('new_password')], 'Passwords must match')
        .required('Confirm password is required')
    }),
    onSubmit: async (values: any) => {
      setResponse({})
      setLoading(true)
      authService.changePassword(values).then((result: _Object) => {
        setResponse(result)
        setLoading(false)
        if (!result.error) {
          formik.resetForm()
          // setVisible(true)
        }
      });
    }
  });

  return (
    <Dialog header="Change Password" className='change-password' visible={visible} style={{ width: '397px' }} onHide={() => { if (!visible) return; setVisible(false); }}>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group">
          <label htmlFor="current_passowrd">Current Password<span className='required-field'>*</span></label>
          <Password
            id="current_passowrd"
            className={`form-control ${formik.errors.current_passowrd && formik.touched.current_passowrd ? 'p-invalid' : ''}`}
            name="current_passowrd"
            placeholder="Current password"
            value={formik.values.current_passowrd}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            toggleMask
          // invalid={formik.values.password}
          />
          {formik.errors.current_passowrd && formik.touched.current_passowrd && (
            <Message severity="error" text={formik.errors.current_passowrd} />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="new_password">New Password<span className='required-field'>*</span></label>
          <Password
            id="new_password"
            className={`form-control ${formik.errors.new_password && formik.touched.new_password ? 'p-invalid' : ''}`}
            name="new_password"
            placeholder="New password"
            value={formik.values.new_password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            toggleMask
          // invalid={formik.values.password}
          />
          {formik.errors.new_password && formik.touched.new_password && (
            <Message severity="error" text={formik.errors.new_password} />
          )}
        </div>
        <div className="form-group">
          <label htmlFor="confirm_password">Confirm Password<span className='required-field'>*</span></label>
          <Password
            id="confirm_password"
            className={`form-control ${formik.errors.confirm_password && formik.touched.confirm_password ? 'p-invalid' : ''}`}
            name="confirm_password"
            placeholder="Confirm Password"
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            feedback={false}
            onBlur={formik.handleBlur}
            toggleMask
          // invalid={formik.values.password}
          />
          {formik.errors.confirm_password && formik.touched.confirm_password && (
            <Message severity="error" text={formik.errors.confirm_password} />
          )}
        </div>
        <Button loading={loading} label="Change Password" className='primary w-full mt-auto' />

        {response.error && (
          <div className="form-group mt-3">
            <Message severity="error" text={response.message || ""} />
          </div>
        )}
        {response.message && !response.error && (
          <div className="form-group mt-3">
            <Message severity="success" text={response.message || ""} />
          </div>
        )}
      </form>
    </Dialog>
  );
};

export default ChangePassword;
