import React, { useRef, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { _Object } from 'utils/common-functions';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { ProjectSingle } from 'utils/types';
import { projectService } from 'services';
import { useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';

// Define the props type
interface AddNewKeyProps {
  keyVisible: boolean;
  initialData: ProjectSingle;
  setKeyVisible: (visible: boolean) => void;
  addNewKey: () => void;
}

interface Translation {
  language_code: string;
  value: string;
}

interface FormValues {
  id?: string;
  key: string;
  translations: Translation[];
}

const AddNewKey = ({ keyVisible, initialData, setKeyVisible, addNewKey }: AddNewKeyProps) => {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const toast = useRef<Toast>(null);

  const formik: _Object = useFormik({
    initialValues: { title: "" },
    enableReinitialize: true,

    validationSchema: Yup.object({
      title: Yup.string().label('Title').required(),
    }),
    onSubmit: async (values: any) => {
      setLoading(true);
      const languageCodes = initialData?.target_languages.map(
        (translation) => translation
      ) || [];

      const newObject: FormValues = {
        key: values.title,
        translations: languageCodes?.map((code) => ({
          language_code: code,
          value: ""
        }))
      };

      setLoading(true);

      id && projectService.createTranslations(id, [newObject]).then((data) => {
        if (!data.error) {
          addNewKey()
          setKeyVisible(false)
          formik.resetForm()
          setLoading(false);
          toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Key added successfully' });

        } else {
          setLoading(false);
          toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message });
        }
        // data.items && setProjects(data)
      })

    }
  });

  return (
    <>
      <Toast ref={toast}></Toast>

      <Dialog header="Add Key Name" visible={keyVisible} style={{ width: '397px' }} onHide={() => {
        formik.resetForm()
        setKeyVisible(false)
      }}>
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="title">Key Name<span className='required-field'>*</span></label>
            <InputText id="title" className='form-control'
              placeholder='Add new key'
              aria-describedby="title-help"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title} />
            {formik.errors.title && formik.touched.title && (
              <Message severity="error" text={formik.errors.title} />
            )}
          </div>
          <Button loading={loading} label={"Create"} className='primary w-full' />
        </form>
      </Dialog>
    </>
  );
};

export default AddNewKey;
