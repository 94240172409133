import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';

import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';

import { ClappingHands, loginImage, LogoImg } from '../../../assets/images';
import { Password } from 'primereact/password';
import { authService } from '../../../services';
import { routes } from '../../../utils/routes';
import SEOHeader from 'components/seo-header';

interface FormValues {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password?: string; // Mark as optional
}


const Register: React.FC = () => {
  const navigate = useNavigate();

  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik<FormValues>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      // confirm_password: ''
    },
    enableReinitialize: true,

    validationSchema: Yup.object({
      first_name: Yup.string().label('First Name').required('First name is required'),
      last_name: Yup.string().label('Last Name').required('Last name is required'),
      email: Yup.string().label('Email').required('Email is required').email('Invalid email address'),
      password: Yup
        .string()
        .required('Password is required')
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/,
          "Password must include a lowercase, uppercase, digit & Symbol."
        ),
      // confirm_password: Yup
      //   .string()
      //   .oneOf([Yup.ref('password')], 'Passwords must match')
      //   .required('Confirm password is required')
    }),
    onSubmit: async (values) => {
      setResponse({})
      setLoading(true);

      // Clone values to apiKeys object
      const apiKeys = { ...values };

      // Delete confirm_password property
      delete apiKeys.confirm_password;
      setLoading(true);
      authService.register(apiKeys)
        .then((data) => {
          setResponse(data)
          !data.error && formik.resetForm();
          setLoading(false);
        });
    },
  });


  return (
    <>
      <SEOHeader title="Register" />

      <div className="auth-details flex h-screen">
        <div className="flex align-items-center justify-content-center flex-column w-6">
          <div className="login-width">
            <img src={LogoImg} alt="Clapping Hands" className='w-13rem' />
            <h2 className="flex align-items-center my-2">Create your account <img src={ClappingHands} alt="Clapping Hands" className='w-2rem' /></h2>
            <p className="mb-4">Today is a new day. It's your day. You shape it. Sign up to start managing your projects.</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                <label htmlFor="first_name">First Name<span className='required-field'>*</span></label>
                <InputText
                  id="first_name"
                  name="first_name"
                  className={`form-control ${formik.errors.first_name && formik.touched.first_name ? 'p-invalid' : ''}`}
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="First Name"
                />
                {formik.errors.first_name && formik.touched.first_name && (
                  <Message severity="error" text={formik.errors.first_name} />
                )}
              </div>

              <div className="form-group">
                <label htmlFor="last_name">Last Name<span className='required-field'>*</span></label>
                <InputText
                  id="last_name"
                  name="last_name"
                  className={`form-control ${formik.errors.last_name && formik.touched.last_name ? 'p-invalid' : ''}`}
                  value={formik.values.last_name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Last Name"
                />
                {formik.errors.last_name && formik.touched.last_name && (
                  <Message severity="error" text={formik.errors.last_name} />
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email">Email ID<span className='required-field'>*</span> <small>(We will send a verification code to this email.)</small></label>
                <InputText
                  id="email"
                  name="email"
                  placeholder="johndoe@email.com"
                  className={`form-control ${formik.errors.email && formik.touched.email ? 'p-invalid' : ''}`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <Message severity="error" text={formik.errors.email} />
                )}
              </div>

              <div className="form-group login-password-field">
                <div className="login-input">
                  <label htmlFor="password">Password<span className='required-field'>*</span></label>
                  <Password
                    id="password"
                    className={`form-control ${formik.errors.password && formik.touched.password ? 'p-invalid' : ''}`}
                    name="password"
                    placeholder="Password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    toggleMask
                    feedback={false}
                  // invalid={formik.values.password}
                  />
                  {formik.errors.password && formik.touched.password && (
                    <Message
                      severity="error"
                      text={formik.errors.password}
                      className={`${formik.errors.password && formik.touched.password ? 'p-invalid' : ''}`}
                    />
                  )}
                </div>
              </div>

              {/* <div className="form-group login-password-field">
                <div className="login-input">
                  <label htmlFor="confirm_password">Confirm Password</label>
                  <Password
                    id="confirm_password"
                    className={`form-control ${formik.errors.confirm_password && formik.touched.confirm_password ? 'p-invalid' : ''}`}
                    name="confirm_password"
                    placeholder="Confirm Password"
                    value={formik.values.confirm_password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    toggleMask
                  // invalid={formik.values.password}
                  />
                  {formik.errors.confirm_password && formik.touched.confirm_password && (
                    <Message severity="error" text={formik.errors.confirm_password} />
                  )}
                </div>
              </div> */}

              <div className="form-group mt-3">
                <div className="flex justify-content-center">
                  <Button loading={loading} type="submit" label="Sign up" className='primary w-full' />
                </div>
                {response.error && (
                  <Message severity="error" text={response.message || ""} />
                )}
              </div>

              {response.message && !response.error && (
                <div className="form-group mt-3">
                  <Message severity="success" text={response.message || ""} />
                </div>
              )}

              <div className="account-details flex justify-content-center mt-3">
                <p className="m-0">Already have an account? &nbsp;<a href="/login">Sign in</a></p>
              </div>
            </form>
          </div>

          <div className='copy-right'>
            <p className='m-0'>© 2023 All Rights Reserved</p>
          </div>
        </div>

        <div className="flex justify-content-center align-items-center bg-white w-6">
          <div className="img-container">
            <img src={loginImage} alt="character" className="character-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
