import React, { Suspense } from 'react'

import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'

import PublicRoutes from '../src/routes/public'
import PrivateRoutes from '../src/routes/private'
import { useSelector } from 'react-redux'
import 'primeflex/primeflex.css';

const App = () => {
  const { isUserLoggedIn } = useSelector((state: any) => state.session)

  return (
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={isUserLoggedIn ? <Navigate to="/projects" /> : <Navigate to="/login" />} />

          {isUserLoggedIn && PrivateRoutes.map((route: any, i: number) => (
            <Route key={i} path={route.path} element={<route.component />} />
          ))}

          {!isUserLoggedIn && PublicRoutes.map((route: any, i: number) => (
            <Route key={i} path={route.path} element={<route.component />} />
          ))}

          <Route path="*" element={<Navigate to={isUserLoggedIn ? "/projects" : "/login"} />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
