import { generateQueryParams } from 'utils/common-functions';
import CommonService from './common.service';

// Define the types for translation and the form's values
interface Translation {
	language_code: string;
	value: string;
}

interface FormValues {
	key: string;
	translations: Translation[];
}

class ProjectService extends CommonService {
	async getProjects(params?: { [key: string]: boolean | string }) {
		return await this.get(`projects/?${generateQueryParams(params)}`)
	}

	async getProjectDetails(id: string | undefined, params?: { [key: string]: boolean | string }) {
		return await this.get(`projects/${id}/translations/?${generateQueryParams(params)}`)
	}

	async importTranslation(id: string | undefined, params: FormData) {
		return await this.post(`projects/${id}/translations/import`, params)
	}

	async downloadTranslation(id: string | undefined, params: { [key: string]: boolean | string }) {
		return await this.post(`projects/${id}/translations/download`, params)
	}

	async createLanguageTranslation(id: string | undefined, params: { [key: string]: boolean | string }) {
		return await this.post(`projects/${id}/translations/language-code`, params)
	}

	async createProject(params: { [key: string]: boolean | string }) {
		return await this.post('projects', params)
	}

	async updateProject(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`projects/${id}`, params)
	}

	async updateTranslationsValues(id: string | undefined, params: { [key: string]: boolean | string | undefined }) {
		return await this.put(`projects/${id}/translations/values`, params)
	}

	async updateTranslations(id: string, params: { [key: string]: boolean | string }) {
		return await this.put(`projects/${id}/translations`, params)
	}

	async createTranslations(id: string, params: FormValues[]) {
		return await this.post(`projects/${id}/translations`, params)
	}

	async deleteTranslation(id: string) {
		return await this.delete(`projects/${id}/translations`)
	}

	async deleteProject(id: string) {
		return await this.delete(`projects/${id}`)
	}
}
export const projectService = new ProjectService();
