import React, { useRef, useState } from 'react';

import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'primereact/button';

import { Dialog } from 'primereact/dialog';
import { _Object, StoreState } from 'utils/common-functions';
import { projectService } from 'services';
import { Message } from 'primereact/message';
import { MultiSelect } from 'primereact/multiselect';
import { useSelector } from 'react-redux';
import { Toast } from 'primereact/toast';

// Define the props type
interface UpdateProjectLanguageProps {
  visible: boolean;
  initialData: _Object;
  setVisible: (visible: boolean) => void;
  getProjects: () => void;
}

const UpdateProjectLanguage = ({ visible, initialData, setVisible, getProjects }: UpdateProjectLanguageProps) => {
  const { languages } = useSelector((state: StoreState) => state.session)
  const [loading, setLoading] = useState(false)
  const toast = useRef<Toast>(null);

  const formik: _Object = useFormik({
    initialValues: initialData,
    enableReinitialize: true,

    validationSchema: Yup.object({
      target_languages: Yup.array()
        .of(Yup.string()) // Ensure each element in the array is a string
        .label('Target Languages')
        .min(1, 'At least one target language is required') // Ensure at least one language is selected
        .required('Target Languages is required'), // General required message for the field
    }),
    onSubmit: async (values: any) => {
      setLoading(true);
      if (initialData.title) {
        projectService.updateProject(initialData.id, {
          "title": values.title,
          "base_language": values.base_language,
          "description": values.description,
          "target_languages": values.target_languages
        }).then((data: _Object) => {
          if (!data.error) {
            formik.resetForm()
            getProjects()
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Languages Updated successfully' });
            setVisible(false)
          } else {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: data.message });
          }
          setLoading(false);
        })
      }
    }
  });

  return (
    <>
      <Toast ref={toast}></Toast>

      <Dialog header="Update Project" visible={visible} style={{ width: '397px' }} onHide={() => {
        formik.resetForm()
        setVisible(false)
      }}>

        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Target Langauge</label>
            <MultiSelect
              name="target_languages"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.target_languages}
              options={[
                ...((languages ?? [])
                  .filter((item) => formik.values.target_languages?.includes(item.code))
                  .map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))),
                ...((languages ?? [])
                  .filter((item) => !formik.values.target_languages?.includes(item.code))
                  .map((item) => ({ label: `${item.name} (${item.code})`, value: item.code }))),
              ]}
              placeholder="Select langauge"
              display="chip"
              filter={true}
              filterBy="label"
              className="w-full"
            />
            {formik.errors.target_languages && formik.touched.target_languages && (
              <Message severity="error" text={formik.errors.target_languages} />
            )}
          </div>

          <Button loading={loading} label={"Update"} className='primary w-full' />
        </form>
      </Dialog>
    </>

  );
};

export default UpdateProjectLanguage;
