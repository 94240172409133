import React, { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';

import '../auth.scss';
import { ClappingHands, loginImage, LogoImg } from '../../../assets/images';
import { setLoggedInUser, setTokenSession } from '../../../redux/slices/session.slice';

import { Button } from 'primereact/button';
import { Password } from 'primereact/password';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { authService } from '../../../services';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../redux/store';
import { routes } from '../../../utils/routes';
import { Link } from 'react-router-dom';
import SEOHeader from 'components/seo-header';

const Login: React.FC = () => {
  const navigate = useNavigate()
  const [response, setResponse] = useState<{
    message?: string
    error?: boolean,
  }>({})
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();  // Use the typed dispatch

  const formik: any = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      password: Yup.string().required('Password is required'),
    }),
    onSubmit: async (values) => {
      setResponse({})
      setLoading(true);

      try {
        const data = await authService.login(values);
        if (data.error === false) {
          dispatch(setTokenSession(data.access_token));
          dispatch(setLoggedInUser());
          navigate(`${routes.dashboard}`);
        } else {
          setResponse(data);
        }
      } catch (err: any) {
        setResponse(err);
      } finally {
        setLoading(false);
      }
    },
  });

  const handleSendMail = () => {
    if (formik.values.email) {
      setLoading(true);

      setResponse({})
      authService.resendAccountVerification({ email: formik.values.email || '' })
        .then((data) => {
          setResponse(data)
          setLoading(false);
        });
    }
  };

  return (
    <>
      <SEOHeader title="Login" />

      <div className="auth-details flex h-screen">
        <div className="flex align-items-center justify-content-center flex-column w-6">
          <div className="login-width">
            <img src={LogoImg} alt="Clapping Hands" className='w-13rem' />
            <h2 className="flex align-items-center my-2">Welcome Back &nbsp;<img src={ClappingHands} alt="Clapping Hands" className='w-2rem' /></h2>
            <p className="mb-4">Today is a new day. It's your day. You shape it. Sign in to start managing your projects.</p>

            <form onSubmit={formik.handleSubmit}>
              <div className="form-group">
                {/* <div className="login-input"> */}
                <label htmlFor="email">Email<span className='required-field'>*</span></label>
                <InputText
                  id="email"
                  className={`form-control ${formik.errors.email && formik.touched.email ? 'p-invalid' : ''}`}
                  name="email"
                  placeholder='Email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email && formik.touched.email && (
                  <Message severity="error" text={formik.errors.email} />
                )}
                {/* </div> */}
              </div>

              <div className="form-group login-password-field">
                {/* <div className="login-input"> */}
                <label htmlFor="password">Password<span className='required-field'>*</span></label>
                <Password
                  id="password"
                  className={`form-control ${formik.errors.password && formik.touched.password ? 'p-invalid' : ''}`}
                  name="password"
                  placeholder="Password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  toggleMask
                  invalid={formik.values.password}
                />
                {formik.errors.password && formik.touched.password && (
                  <Message severity="error" text={formik.errors.password} />
                )}
                {/* </div> */}
              </div>

              <div className="flex justify-content-end align-items-center my-3">
                <p className='m-0'>
                  <Link className="forgot-password" to="/forget-password">Forgot Password?</Link>
                  {/* <a href="/forget-password" className="forgot-password">
                Forgot Password?
                </a> */}
                </p>
              </div>

              <div className="flex justify-content-center">
                <Button loading={loading} type="submit" label="Sign in" className='primary w-full' />
              </div>

              {response.error && (
                <div className="form-group mt-3">
                  <Message severity="error" text={
                    response.message === "Your account is not verified. Please check your email for the verification link" ?
                      <>
                        {response.message || ""}{", "}
                        <Button
                          type='button'
                          label="click here to request a new one"
                          className="p-button-link underline p-0" // This styles the button as a link
                          onClick={handleSendMail}
                        />
                      </> : response.message || ""
                  }
                  />
                </div>
              )}
              {response.message && !response.error && (
                <div className="form-group mt-3">
                  <Message severity="success" text={response.message || ""} />
                </div>
              )}
              <div className="account-details flex justify-content-center mt-5">
                <p className='mb-0'>
                  Don't you have an account? &nbsp;<Link to="/register">Sign up</Link>
                </p>
              </div>
            </form>
          </div>

          <div className='copy-right'>
            <p className='m-0'>© 2023 All Rights Reserved</p>
          </div>
        </div>


        <div className="flex justify-content-center align-items-center bg-white w-6">
          <div className="img-container">
            <img src={loginImage} alt="character" className="character-image" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
