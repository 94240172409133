import CommonService from './common.service';

class AuthService extends CommonService {
	async register(params: { [key: string]: string }) {
		return await this.post('register', params);
	}

	async login(params: { [key: string]: string }) {
		return await this.post('login', params);
	}

	async forgotPassword(params: { [key: string]: string }) {
		return await this.post('reset-password-email', params)
	}

	async updatePassword(params: { [key: string]: string | null }) {
		return await this.post('update-password', params)
	}
	async changePassword(params: { [key: string]: string | null }) {
		return await this.post('change-password', params)
	}

	async getMe() {
		return await this.get('me')
	}

	async getLanguages() {
		return await this.get('languages')
	}

	async editProfile(params: { [key: string]: boolean | string }) {
		return await this.put('profile', params)
	}

	async accountVerification(params: { [key: string]: string }) {
		return await this.post('account-verification', params);
	}

	async resendAccountVerification(params: { [key: string]: string }) {
		return await this.post('resend-verification', params);
	}
}
export const authService = new AuthService();