import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import SEOHeader from 'components/seo-header';
import { ClappingHands, loginImage, LogoImg } from 'assets/images';
import { Message } from 'primereact/message';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { authService } from 'services';

const AccountVerification: React.FC = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);

	const [response, setResponse] = useState<{
		message?: string
		error?: boolean,
	}>({})
	const [loading, setLoading] = useState<boolean>(false);

	const formik = useFormik({
		initialValues: {
			id: queryParams.get('id') || '',
			key: queryParams.get('key') || '',
		},
		enableReinitialize: true,
		onSubmit: async (values) => {
			setResponse({})
			setLoading(true);
			authService.accountVerification(values)
				.then((data) => {
					setResponse(data)
					if (!data.error) {
						// Navigate to login page after 3 seconds
						setTimeout(() => {
							navigate('/login');
						}, 3000);
					} setLoading(false);
				});
		},
	});

	return (
		<>
			<SEOHeader title="Account Verification" />
			<div className="auth-details flex h-screen">
				<div className="flex align-items-center justify-content-center flex-column w-6">
					<div className="login-width">
						<img src={LogoImg} alt="Clapping Hands" className='w-13rem' />
						<h2 className="flex align-items-center my-2">Account Verification <img src={ClappingHands} alt="Clapping Hands" className='w-2rem' /></h2>
						<p className="mb-4">You're just one step away from accessing your account. Click the button below to verify your account and start your journey!</p>

						<form onSubmit={formik.handleSubmit}>

							<div className="flex justify-content-center">
								<Button loading={loading} type="submit" label="VERIFY" className='primary w-full' />
							</div>

							{response.error && (
								<div className="form-group mt-3">
									<Message severity="error" text={response.message || ""} />
								</div>
							)}
							{response.message && !response.error && (
								<div className="form-group mt-3">
									<Message severity="success" text={response.message || ""} />
								</div>
							)}

							<div className="account-details flex justify-content-center mt-3">
								<p>Already have an account? &nbsp;<Link to="/login">Sign in</Link></p>
							</div>
						</form>
					</div>

					<div className='copy-right mt-auto mb-4'>
						<p className='mt-4'>© 2023 All Rights Reserved</p>
					</div>
				</div>

				<div className="flex justify-content-center align-items-center bg-white w-6">
					<div className="img-container">
						<img src={loginImage} alt="character" className="character-image" />
					</div>
				</div>
			</div>
		</>
	);
}

export default AccountVerification;
