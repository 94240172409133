import moment from 'moment';
import { SessionState } from '../redux/slices/session.slice';
import { routes } from './routes';

export {
	routes
}

export interface _Object {
	[key: string]: any
}


export interface StoreState {
  session: SessionState;
}

export const generateQueryParams = (args: any) => {
	let str = ''
	let replace = ''
	for (const key in args) {
		if (typeof (args[key]) === 'object') {
			for (const key1 in args[key]) {
				str += `&filters[${key1}]=${args[key][key1]}`
				replace = str.slice(1)
			}
		} else {
			str += `&${key}=${args[key]}`
			replace = str.slice(1)
		}
	}
	return replace
}

export const doDateFormat: any = (date: string, format = 'MMM DD, YYYY') => {
	return moment(date).format(format)
}